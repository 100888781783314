export const HEAD_ITEMS = {
  APPLICATION_ID: 'Application ID',
  COMPANY_NAME: 'Company name',
  SUBMISSION: 'Submission date',
  EMAIL: 'Email',
  PROCESS_DATE: 'Process date',
}

export const APPLICATION_DUPLICATE_HEAD_ITEMS = {
  COMPANY_NAME: 'Company name',
  SUBMISSION: 'Submission date',
  EMAIL: 'Email',
  PHONE_NUMBER: 'Phone number',
  PROCESS_DATE: 'Process date',
}

export const APPLICATION_TITLE = 'Application details'

export const APPLICATIONS_STATUS_VALUES = {
  WaitingList: 'Waiting list',
  WaitingForCall: 'Waiting for call',
  WaitingForSign: 'Waiting for signing',
  Approve: 'Approved',
  Reject: 'Rejected',
}

export const APPLICATION = {
  CONTACT_INFORMATION: 'Contact information',
  SUPPLIER_LOCATION: 'Supplier location and fleet',
  OTHER: 'Other information',
  STATUS_INFO: 'Application processing info',
}

export const APPLICATION_FORM_VALUES = {
  NAME: {
    ID: 'companyName',
    LABEL: 'Company name',
  },
  PERSON_NAME: {
    ID: 'contactName',
    LABEL: "Contact's person name",
  },
  COUNTRY: {
    ID: 'companyCountryName',
    LABEL: 'Country',
  },
  CONTACT_NAME: {
    ID: 'contactName',
    LABEL: 'Contact name',
  },
  EMAIL: {
    ID: 'contactEmail',
    LABEL: 'Email',
  },
  CURRENT_PHONE: {
    ID: 'currentPhone',
    LABEL: 'Phone number',
  },
  VEHICLE_COUNT: {
    ID: 'vehiclesCount',
    LABEL: 'Number of vehicles in your fleet (approximate)',
  },
  API_TYPE: {
    ID: 'apiIntegrationType',
    LABEL: 'What type of API integration do you have?',
  },
  LOCATIONS_COUNT: {
    ID: 'locationsCount',
    LABEL: 'Number of locations you operate in',
  },
  COUNTRIES_FLEET: {
    ID: 'countriesWithFleets',
    LABEL: 'Country/ies you have a fleet in',
  },
  LOCATION_TYPE: {
    ID: 'locationTypes',
    LABEL: 'Type of location',
  },
  STATUS: {
    ID: 'status',
    LABEL: 'Select status',
    PLACEHOLDER: 'Please select a status',
    REQUIRED_MESSAGE: 'Please select a status.',
  },
  COMMENT: {
    ID: 'comment',
    LABEL: 'Comment',
    PLACEHOLDER: 'Please write a comment',
  },
  CONNECTION_TYPE: {
    LABEL: 'Type of integration <mark>*</mark>',
    ID: 'connectionType',
    REQUIRED_MESSAGE: 'Please select an integration type.',
  },
  REASON_TYPE: {
    ID: 'rejectReasonType',
    LABEL: 'Select reason',
    PLACEHOLDER: 'Please select a reason',
    REQUIRED_MESSAGE: 'Please select a reason.',
  },
  REASON: {
    ID: 'rejectReason',
    LABEL: 'Reason',
    PLACEHOLDER: 'Please enter your reason',
    REQUIRED_MESSAGE: 'Please enter your reason.',
  },
}

export const ATTACHMENT_TITLE =
  'Please upload images of your pick-up locations, fleet and a file with your rental terms and conditions:'
export const DOWNLOAD_ALL = 'Download all files'

export const APPLICATION_RADIO_BUTTON = {
  API: 'API Integration',
  STATIC: 'Static',
}

export const SEARCH_BY = 'Search by:'
export const DUPLICATE = 'Duplicate'
export const ID = 'ID'

export const APPLICATION_DUPLICATE = {
  TITLE: 'Application duplicate',
  DETAILS: 'Application duplicate details',
}
